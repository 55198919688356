<template>
  <div class="priviewAll1">
    <!-- <iframe src="https://profile.alibaba.com//profile/my_profile.htm?m=B1PID1cxRtWzaG1efUdMYOP26fX9QyCTEoq3bPDs2r_2XfzX0*&displayLanguage=zh_CN"
    style="width: 100%;height: 100%;border: none;"></iframe> -->
  </div>
</template>

<script>
  export default{
    name:"test",
    data(){
      return{

      }
    },
    created() {
      this.get_Html('http://192.168.0.21:8080/#/productionplan_view');
    },
    methods:{
      get_Html(url){
        $.ajax({
            url:url,
            type:"GET",
            dataType:"html",
            success:function(result){
                console.log(result);
                // //正则表达式获取body块
                // var reg = /[\s\S]*<\/body>/g;
                // var html = reg.exec(result)[0];
                // //然后用filter来筛选对应块对象，如：class='aa'
                // var aa = $(html).filter(".aa");
                // var aahtml = aa.html();
                // console.log(aahtml);
                //获取内容后可以插入当前html中。。。
            }
        });
      }
    }
  }
</script>

<style>
  .priviewAll1{
    width: 100%;
    height: calc(100% - 20px);
    padding: 10px 0;
  }
</style>
